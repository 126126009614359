html {
  background-color: var(--grey);
}

* {
  font-family: var(--body-medium);
}

.logo {
  flex-shrink: 0;
  width: 87px;
  height: 30px;
  position: relative;
  overflow: hidden;
}

.nav-link {
  white-space: nowrap;
  min-width: 84px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.large-link {
  padding: var(--padding-12xl) 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav-link1 {
  white-space: nowrap;
  min-width: 95px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.large-link1 {
  padding: var(--padding-12xl) 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav-link2 {
  white-space: nowrap;
  min-width: 85px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.large-link2, .nav-link-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.large-link2 {
  padding: var(--padding-12xl) 0;
}

.nav-link-container {
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-black);
  margin: 0;
}

.secondary-link {
  white-space: nowrap;
  min-width: 61px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.linksmall-link {
  padding: var(--padding-12xl) 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.secondary-link1 {
  min-width: 44px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.linksmall-link1 {
  padding: var(--padding-12xl) 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.secondary-link2 {
  min-width: 53px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.linksmall-link2 {
  padding: var(--padding-12xl) 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.secondary-link3 {
  min-width: 53px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.linksmall-link3 {
  padding: var(--padding-12xl) 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.secondary-link4 {
  white-space: nowrap;
  min-width: 69px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.linksmall-link4 {
  padding: var(--padding-12xl) 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.secondary-link5 {
  min-width: 58px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.linksmall-link5 {
  padding: var(--padding-12xl) 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.secondary-link6 {
  font-size: var(--font-size-xs);
  color: var(--white, #fff);
  text-align: left;
  white-space: nowrap;
  min-width: 62px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.linksmall-link6 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.arrow-icon {
  width: 15.5px;
  height: 14px;
  position: relative;
}

.button-main, .links-container, .secondary-links {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.button-main {
  cursor: pointer;
  padding: var(--padding-3xs) var(--padding-lg);
  background-color: var(--green-dark-green);
  gap: var(--gap-3xs);
  border: 0;
}

.links-container, .secondary-links {
  gap: var(--gap-base);
  max-width: 100%;
}

.links-container {
  justify-content: flex-end;
  gap: var(--gap-21xl);
}

.search-icon {
  width: 19px;
  height: 19px;
  position: relative;
}

.nav-header, .secondary-link7 {
  text-align: left;
}

.nav-header {
  background-color: var(--color-gray-100);
  padding: 0 var(--padding-61xl);
  box-sizing: border-box;
  z-index: 99;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: var(--gap-xl);
  font-size: var(--font-size-xs);
  color: var(--color-black);
  flex-direction: row;
  max-width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  box-shadow: 0 3px 4px #00000014;
}

.secondary-link7 {
  font-size: var(--body-medium-size);
  color: var(--white);
  line-height: 18px;
  position: relative;
}

.linksmall-link7 {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.arrow-icon1 {
  width: 15.5px;
  height: 14px;
  position: relative;
}

.arrow-wrapper, .button-newConvo {
  padding: var(--padding-11xs) 0 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.button-newConvo {
  cursor: pointer;
  padding: var(--padding-xl) var(--padding-36xl-2) var(--padding-xl) var(--padding-36xl-3);
  background-color: var(--green-dark-green);
  gap: var(--gap-3xs);
  z-index: 1;
  border: 0;
  flex-direction: row;
}

.title-previous-conversations {
  color: var(--Black, #1b1c1c);
  margin: 0;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.question-text {
  flex: 1;
  position: relative;
}

.question, .question1 {
  background-color: var(--white);
  border: 1px solid var(--green-dark-green);
  padding: var(--padding-lg) var(--padding-4xl);
  white-space: nowrap;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.question2 {
  background-color: var(--white);
  border: 1px solid var(--green-dark-green);
  padding: var(--padding-lg) var(--padding-4xl);
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.previous-conversations-container, .questions {
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-lg);
  flex-direction: column;
  display: flex;
}

.questions {
  font-size: var(--body-medium-size);
  color: var(--green-dark-green);
}

.previous-conversations-container {
  z-index: 1;
  padding: 0;
}

.frame-child {
  border-top: 1px solid var(--color-darkslategray-100);
  box-sizing: border-box;
  z-index: 1;
  align-self: stretch;
  height: 1px;
  position: relative;
}

.secondary-link8 {
  line-height: 18px;
  position: relative;
}

.linksmall-link8 {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.linksmall-link8 a {
  color: var(--green-dark-green);
  text-decoration: none;
}

.arrow-icon2 {
  cursor: pointer;
  width: 15.5px;
  height: 14px;
  position: relative;
}

.arrow-container {
  padding: var(--padding-11xs) 0 0;
  flex-direction: column;
  justify-content: flex-start;
}

.arrow-container, .side-panel, .button-main2 {
  align-items: flex-start;
  display: flex;
}

.button-main2 {
  padding: 0 var(--padding-xl);
  justify-content: center;
  align-self: stretch;
  gap: var(--gap-3xs);
  z-index: 1;
  font-size: var(--body-medium-size);
  color: var(--green-dark-green);
  flex-direction: row;
}

a .button-main2 {
  text-decoration: none;
}

.side-panel {
  padding-top: var(--padding-lg);
  flex-direction: column;
  justify-content: flex-start;
  gap: 41.7px;
  width: 345px;
  max-width: 100%;
}

.sticky-panel {
  z-index: 1000;
  position: sticky;
  top: 0;
}

.background {
  backdrop-filter: blur(60px);
  background-color: var(--green-green);
  flex: 1;
  align-self: stretch;
  display: none;
  position: relative;
}

.search-title {
  font-size: inherit;
  z-index: 1;
  margin: 0;
  font-weight: 700;
  line-height: 70px;
  position: relative;
}

.search-subtitle {
  font-size: var(--body-medium-size);
  z-index: 1;
  flex-shrink: 0;
  width: 1010px;
  max-width: 100%;
  height: 44px;
  line-height: 22px;
  display: inline-block;
  position: relative;
}

.search-intro {
  justify-content: flex-start;
  align-items: center;
  gap: var(--gap-xs);
  text-align: center;
  flex-direction: column;
  max-width: 100%;
  padding: 156px 0 0;
  display: flex;
}

#question-prompt, .container-search-textarea {
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  display: flex;
}

#question-prompt {
  font-size: var(--font-size-xl);
  color: var(--white);
  background-color: #0000;
  border: 0;
  outline: 0;
  width: 100%;
  min-width: 250px;
  height: 20px;
}

#question-prompt::-webkit-scrollbar {
  display: none;
}

.container-search-textarea {
  border-bottom: 1px solid var(--white);
  box-sizing: border-box;
  padding: var(--padding-xl) 0 var(--padding-lg);
  min-width: 349px;
}

.secondary-link9 {
  font-size: var(--font-size-xs);
  color: var(--black);
  text-align: left;
  min-width: 20px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.linksmall-link9 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.arrow-icon3 {
  width: 15.5px;
  height: 14px;
  position: relative;
}

.button-main3, .button-secondary {
  background-color: var(--white);
  flex-direction: row;
  display: flex;
}

.button-main3 {
  border: 1px solid var(--green-dark-green);
  padding: var(--padding-5xs) 39px;
  justify-content: center;
  align-items: center;
  gap: var(--gap-3xs);
}

.button-secondary {
  cursor: pointer;
  border: 0;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
}

.container-search-form, .search1 {
  box-sizing: border-box;
  flex-direction: row;
}

.search1 {
  padding: var(--padding-3xs);
  z-index: 1;
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 12px 10px;
  max-width: 100%;
  display: flex;
}

.container-search-form, .search, .search-wrapper {
  max-width: 100%;
  display: flex;
}

.container-search-form {
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  padding: 0 48px 0 49px;
  display: block;
}

.search {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 26px;
}

.search-wrapper {
  padding: 0 var(--padding-2xl) 0 var(--padding-xl);
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
}

.categories-child {
  background-color: var(--green-dark-green);
  width: 1375px;
  max-width: 100%;
  height: 390px;
  display: none;
  position: relative;
}

.footer-column-title {
  min-width: 123px;
  display: inline-block;
}

.healthcare, .footer-column-title, .retail, .telecommunications {
  line-height: 24px;
  position: relative;
}

.healthcare, .retail {
  display: inline-block;
}

.retail {
  width: 52px;
}

.healthcare {
  min-width: 97px;
}

.government {
  min-width: 111px;
  display: inline-block;
}

.financial-services, .government {
  line-height: 24px;
  position: relative;
}

.industries-column-a {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-4xs);
  flex-direction: column;
  min-width: 179px;
  display: flex;
}

.utilities {
  min-width: 69px;
  display: inline-block;
}

.manufacturing, .utilities {
  line-height: 24px;
  position: relative;
}

.media {
  width: 57px;
  display: inline-block;
}

.media, .tolling-and-smart, .transportation {
  line-height: 24px;
  position: relative;
}

.industries-column-b {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-4xs);
  flex-direction: column;
  flex: 1;
  min-width: 147px;
  display: flex;
}

.categories-container, .industries-container {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-5xl);
  display: flex;
}

.industries-container {
  text-align: left;
  font-size: var(--body-medium-size);
  flex-direction: row;
  align-self: stretch;
}

.categories-container {
  flex-direction: column;
  width: 429px;
  max-width: calc(100% - 371px);
}

.categories, .main-content {
  color: var(--white);
  box-sizing: border-box;
  z-index: 1;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
  display: flex;
}

.categories {
  background-color: var(--green-dark-green);
  text-align: right;
  font-size: var(--font-size-5xl);
  flex-direction: row;
  align-self: stretch;
  min-height: 390px;
  padding: 85.3px 215px 85px;
}

.main-content {
  backdrop-filter: blur(60px);
  background-color: var(--green-green);
  text-align: center;
  font-size: var(--h1-size);
  margin-top: var(--padding-lg);
  flex-direction: column;
  flex: 1;
  gap: 218px;
  min-width: 894px;
}

.frame-parent {
  gap: var(--gap-21xl);
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--black);
  flex-flow: wrap;
  flex: 1;
  max-width: 100%;
}

.body-container, .body-container-inner, .frame-parent {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.body-container-inner {
  padding: 0 var(--padding-61xl);
  box-sizing: border-box;
  flex-direction: row;
  align-self: stretch;
  max-width: 100%;
}

.body-container {
  background-color: var(--grey);
  gap: var(--gap-41xl);
  letter-spacing: normal;
  flex-direction: column;
  width: 100%;
  line-height: normal;
  position: relative;
  overflow: hidden;
}

#chat_container {
  text-align: left;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  flex-direction: column;
  flex: 1;
  gap: 30px;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  display: flex;
  overflow-y: scroll;
}

#chat_container::-webkit-scrollbar {
  display: none;
}

textarea {
  color: var(--White, #fff);
  background: none;
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
  padding: 10px;
  font-size: 18px;
}

textarea::placeholder {
  color: var(--White, #fff);
}

#question-prompt-refine {
  color: var(--Black, #1b1c1c);
  line-height: 20px;
}

#question-prompt-refine::placeholder {
  color: var(--color-darkslategray-100);
}

.refine-search form {
  justify-content: flex-start;
  align-items: center;
  gap: var(--gap-3xs);
  flex-direction: row;
  width: 100%;
  display: flex;
}

#q0btn, #q1btn, #q2btn {
  cursor: pointer;
}

.chat {
  border-radius: 10px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.message {
  color: var(--green-dark-green, #00393a);
  letter-spacing: -.4px;
  white-space: pre-wrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: var(--green-lightest-green, #b4e1e7);
  flex: 1;
  max-width: 100%;
  padding: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  overflow-x: scroll;
}

.message::-webkit-scrollbar {
  display: none;
}

.false .chat .message:after {
  content: "";
  border: 15px solid #0000;
  border-top-color: var(--green-lightest-green, #b4e1e7);
  border-bottom: 0;
  width: 0;
  height: 0;
  margin-bottom: -15px;
  margin-left: -15px;
  position: absolute;
  bottom: 0;
  right: 5%;
}

.ai .chat .message {
  color: var(--White, #fff);
  background: #b4e1e714;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.ai .chat .message ol {
  counter-reset: item;
  padding-left: 0;
  list-style-type: none;
}

.ai .chat .message ol li {
  counter-increment: item;
  margin-bottom: 20px;
  padding-left: 50px;
  position: relative;
}

.ai .chat .message ol li:before {
  content: counter(item);
  text-align: center;
  border: 1px solid #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.ai .chat .message a {
  color: var(--White, #fff);
}

.refine-search, .search2 {
  justify-content: flex-start;
  align-self: stretch;
  display: flex;
}

.search2 {
  padding: var(--padding-3xs);
  align-items: center;
  gap: var(--gap-xs);
  flex-direction: row;
}

.refine-your-search {
  font-size: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: 600;
  position: relative;
}

.copy-wrapper, .copy1 {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.copy1 {
  font-family: var(--font-manrope);
  font-size: var(--body-mediun-size);
  color: var(--black);
  background-color: #0000;
  border: 0;
  outline: 0;
  width: 221px;
  height: 20px;
}

.copy-wrapper {
  border-bottom: 1px solid var(--green-dark-green);
  box-sizing: border-box;
  padding: var(--padding-xl) 0 var(--padding-lg);
  flex: 1;
  min-width: 171px;
}

.arrow-icon5 {
  width: 15.5px;
  height: 14px;
  position: relative;
}

.secondary-l-xl {
  cursor: pointer;
  border: 1px solid var(--green-dark-green);
  padding: var(--padding-base);
  background-color: var(--white);
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
}

.refine-search {
  align-items: flex-start;
  gap: var(--gap-7xs);
  z-index: 1;
  flex-direction: column;
  display: none;
}

.sources-header {
  color: var(--White, #fff);
  letter-spacing: -.4px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.sources-list {
  color: var(--White, #fff);
  counter-reset: item;
  padding-left: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  list-style-type: none;
}

.sources-list li {
  margin-bottom: 10px;
}

.sources-list li:after {
  content: "    →";
  color: var(--White, #fff);
}

#question-prompt, #prompt {
  color: var(--white);
  background-color: #0000;
  border: 0;
  outline: 0;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 32px);
  min-width: 300px;
  max-width: 100%;
  height: 20px;
  font-size: 20px;
  display: flex;
}

@media screen and (width <= 1200px) {
  .nav-header {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }

  .categories, .container-search-form {
    padding-left: var(--padding-5xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }

  .categories {
    padding-left: var(--padding-88xl);
    padding-right: var(--padding-88xl);
  }

  .main-content {
    gap: 109px;
    min-width: 100%;
  }
}

@media screen and (width <= 1050px) {
  .nav-link-container {
    display: none;
  }

  .title-previous-conversations {
    font-size: var(--font-size-7xl);
  }

  .search-title {
    font-size: 45px;
    line-height: 56px;
  }

  .main-content {
    box-sizing: border-box;
    padding-top: 101px;
  }

  .body-container-inner {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }

  .body-container {
    gap: var(--gap-11xl);
  }
}

@media screen and (width <= 750px) {
  .industries-column-a {
    flex: 1;
  }

  .industries-container {
    flex-wrap: wrap;
  }

  .categories {
    padding-left: var(--padding-34xl);
    padding-right: var(--padding-34xl);
    box-sizing: border-box;
  }

  .main-content {
    box-sizing: border-box;
    gap: 54px;
    padding-top: 66px;
  }

  .frame-parent {
    gap: var(--gap-xl);
  }
}

@media screen and (width <= 450px) {
  .secondary-links {
    display: none;
  }

  .links-container {
    gap: var(--gap-xl);
  }

  .button-newConvo {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }

  .title-previous-conversations {
    font-size: var(--font-size-lgi);
  }

  .side-panel {
    gap: var(--gap-2xl);
  }

  .search-title {
    font-size: 34px;
    line-height: 42px;
  }

  .footer-column-title {
    font-size: var(--font-size-lgi);
    line-height: 19px;
  }

  .categories {
    padding: 55px var(--padding-xl);
    box-sizing: border-box;
  }

  .main-content {
    gap: 27px;
  }
}
/*# sourceMappingURL=index.81358aa8.css.map */
